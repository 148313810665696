import * as React from "react"
import Herobgimage from "../images/herobg.jpeg"

import renovation from "../images/icons/Modular wardrobe.png"

import property from "../images/icons/Modular Kitchen .png"

import project from "../images/icons/Renovation.png"

import Lighting from "../images/icons/Lighting.png"

import general from "../images/icons/Modular TV unit.png"

import interior from "../images/icons/Storage wall unit.png"

import material from "../images/icons/False Ceiling.png"

import realestate from "../images/icons/Wall decor.png"

import woodenflooring from "../images/icons/wodden flooring.png"

import commercial from "../images/icons/Wood work.png"

import cta_bg from "../images/jpg/footer-top-bg.jpg"

import imageone from "../images/towcol2.jpeg"

import imagetwo from "../images/twocol1.jpeg"

import {
  Seo,
  Layout,
  Content_section,
  Our_project,
  Services,
  Cta_section,
  HeroBanner,
  Twocolsec,
  Imagesection,
  Imagetwocol,
} from "components"

const herodata = {
  bgimage: Herobgimage,
  title: " Complete solutions for residential and commercial Modular interiors",
  description: "You don’t have to pay a premium to get luxury",
  heading: "Needed a quotation for your project?",
  button: [
    {
      openInNewWindow: true,
      buttonTitle: "Get quote",
      phno: "https://wa.me/message/3SP5IKK67RSWO1",
      buttonUrl: "#",
    },
  ],
}

const servicesdata = [
  {
    title: "Services we offer",
    box: [
      {
        title: "Modular Wardrobe",
        image: renovation,
        Link: "modular-wardrobe",
      },
      {
        title: "Modular Kitchen",
        image: property,
        Link: "moduler-kitchen",
      },

      {
        title: "Modular TV units",
        image: general,
        Link: "moduler-tv-unit",
      },
      {
        title: "Crockery Units",
        image: interior,
        Link: "#",
      },
      {
        title: "False ceiling",
        image: material,
        Link: "#",
      },
      {
        title: "Wall Decor",
        image: realestate,
        Link: "#",
      },
      {
        title: "Renovation",
        image: project,
        Link: "#",
      },
      {
        title: "Luxury Tall Wardrobes",
        image: commercial,
        Link: "/luxury-tall-wardrobes",
      },
      {
        title: "Lighting",
        image: Lighting,
        Link: "#",
      },
      {
        title: "Wooden Flooring",
        image: woodenflooring,
        Link: "#",
      },
    ],
  },
]
const Twocolumn = {
  imagePosition: "Left",
  imageone: imageone,
  imagetwo: imagetwo,
  title: "Alpha Modular Installation & Renovation in Bangalore.",
  descrption1:
    "We provide Installation of Modular Kitchen, Modular Wardrobe, Modular TV Units, Storage Wall Units, False Ceiling, Woodwork, Renovation, Lighting , Wooden Flooring and Wall Decor.",
  descrption2:
    "We are proud to provide you the best quality product with outstanding finish that will make your house as home.",
  descrption3:
    "Our interior design and renovations experts can visit you in Bangalore and the surrounding area.",
  button: [
    {
      openInNewWindow: false,
      buttonTitle: "CALL NOW",
      phno: "tel:+917975344458",
      buttonUrl: "#",
    },
  ],
}

const cta_content = {
  title: "Get a Free Consultation ",
  description: "Let's Work Together",
  buttonTitle: "call us",
  buttonLink: "tel:+917975344458",
  background_image: { cta_bg },
}
const content = [
  {
    title: "Advantage of Modular Installation in Bangalore",
    rignt_title:
      "Where a Modular Kitchen beats a Traditional Carpenter made Kitchen",
    list: [
      { list: "Better Fit & Finish – since it is factory made" },
      { list: "No hassle on site – only site preparation is required" },
      {
        list: "Modularity – Wet unit can be taken out and replaced in case of wear",
      },
      { list: "Installed on Legs that protect the carcass from water & wear" },
      { list: "Quicker to build & installe" },
      {
        list: "We offer one year free onsite service and upto 10 years material warranty",
      },
      { list: "15 to 20 days of delivery time guaranteed" },
    ],
  },
]
const project_data = {
  title: "Our Recent Projects",
  buttonTitle: "call us",
  buttonLink: "tel:+917975344458",
  Our_project: [
    {
      image: "design1",
    },
    {
      image: "design2",
    },
    {
      image: "design3",
    },
    {
      image: "design4",
    },
    {
      image: "design5",
    },
    {
      image: "design6",
    },
    {
      image: "design7",
    },
    {
      image: "design8",
    },
    {
      image: "design9",
    },
    {
      image: "design10",
    },
    {
      image: "design11",
    },
    {
      image: "design12",
    },
  ],
}
const IndexPage = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="Home" location={location?.pathname} />
      <HeroBanner data={herodata} />
      <Twocolsec data={Twocolumn} />
      <Imagetwocol />
      <Content_section data={content} />
      <Services data={servicesdata} />
      {/* <Imagesection /> */}
      <Our_project data={project_data} />
      <Cta_section data={cta_content} />
    </Layout>
  )
}

export default IndexPage
